/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'
import HeroImage from 'components/elements/HeroImage'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import AgendaBlock from 'components/AgendaBlock'

// Third party
import parse, { domToReact } from 'html-react-parser'

const Sidebar = styled.div`
  position: relative;
`

const StyledContent = styled(ParseContent)`
  & a {
    color: ${(props) => props.theme.color.text.contrast};
    font-weight: bold;
  }
`

const Sermon = styled.div``

let contentfull

const findAndReplace = (content) => {
  if (content.indexOf('wp-content/') !== -1) {
    contentfull = `https://wpadmin.cgkwerkendam.nl${content}`
  }

  return <source src={contentfull} type="audio/mp3" />
}

const PrekenTemplate = ({
  data: {
    preek: { title, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      {/* <HeroImage fields={acf.banner} /> */}

      <div className="container py-lg-5">
        <div className="row py-5">
          <div className="col-lg-7">
            <TitleDefault className="mb-5">{title}</TitleDefault>
            {acf.sermon.map(
              (
                { time, predecessor, schriftlezing, text, sermonURL },
                index
              ) => (
                // eslint-disable-next-line react/no-array-index-key
                <Sermon className="mb-5" key={index}>
                  <h4 className="mb-2">
                    <span>{time}</span> <span>{predecessor}</span>
                  </h4>
                  <p className="mb-2">
                    <strong>Schriftlezing:</strong> {schriftlezing}
                  </p>
                  <p>
                    <strong>Tekst:</strong> {text}
                  </p>
                  <audio controls>{findAndReplace(sermonURL)}</audio>
                </Sermon>
              )
            )}
          </div>
          <div className="col-lg-1 d-none d-lg-block" />
          <Sidebar className="col-lg-4 px-3">
            <AgendaBlock />
          </Sidebar>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    preek: wordpressWpPrekenarchief(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        sermon {
          time
          predecessor
          schriftlezing
          text
          sermonURL: sermon_url
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PrekenTemplate
